import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { theme } from '../src/theme'

const Layout = ({ title, description, keywords, children, noRobots, image }) => {
  return <ThemeProvider theme={theme}>
    <>
      <Head>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width, maximum-scale=1' />
        
        <link href='/favicon.png' rel='icon' type='image/x-ico' />
        <link rel='shortcut icon' href='/favicon.png' type='image/x-icon' />
        <link href='/favicon.png' rel='apple-touch-icon-precomposed' />

        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />

        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:image' content={image ? `${process.env.CDN}/${image}` : '/challenge-hound-logo.png'} />
        <meta property='og:description' content={description} />
        <meta property='fb:app_id' content='1855700951367915' />

        <meta name="google-site-verification" content="8lHUAOQqtsCs2ysRmBcsgYmIykr8XkaQ5jnSdV_H91Y" />

        <meta httpEquiv='content-type' content='text/html; charset=utf8' />
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='author' content='Challenge Hound' />
        {noRobots ? <meta name='robots' content='noindex' /> : <meta name='robots' content='index, follow' />}
        {noRobots && <meta name="googlebot" content='noindex' />}
        <meta name='revisit-after' content='1 Week' />
        <meta name='language' content='EN' />
        <meta name='copyright' content='2023' />
        <meta name="theme-color" content="#FF8001" />

        <link rel="preconnect" href="https://www.challengehound.com" crossOrigin='anonymous' />
        <link as='font' rel='preload' href='/fonts/AveriaSerifLibre-Regular.woff2' type='font/woff2' crossOrigin='anonymous' />
        <link as='font' rel='preload' href='/fonts/Muli.woff' type='font/woff' crossOrigin='anonymous' />
      </Head>
      { children }
    </>
  </ThemeProvider>
}

export default Layout
